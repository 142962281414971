import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
function TitleHeader() {
  

    return (title());
}

export  function title() {
  var number = "+91-9622262227";
  var email = "info@wahabsinn.com";
    const isDesktop = window.matchMedia("(max-width: 415px)").matches;
    return (
        <div style={{position: 'fixed', width:"300%"}}>
    {/* for mobile */}
                {isDesktop && (      <div>
      <div id="top-title" className='title' style = {{background: "rgb(6, 132, 152)", paddingTop:'0px', paddingBottom:'7px', paddingLeft:'5px', paddingRight:'5px'}}>
        <div className='container-title clearfix' >
          <div id="info-contact" class="title-row-mobile" >
          <div>
            <FontAwesomeIcon class="title-block" style={{ height: "10px" , width: "10px"}} icon={faPhone} />
            <div class="title-block" style={{ fontSize: "135%"}}>{number}</div>
            </div>
            <div>
            <FontAwesomeIcon class="title-block" style={{ height: "10px" , width: "10px"}} icon={faEnvelope} />
            <a class="title-block" href="`mailto:{email}`">
            <span class="title-block" id="et-info-email" style={{ fontSize: "130%"}}>{email}</span>
            </a>
            </div>
          </div>
        </div>
      </div>
      </div>)}
    {/* for desktop */}
                {!isDesktop && (      <div>
      <div id="top-title" className='title' style = {{background: "rgb(6, 132, 152)", paddingTop:'0px', paddingBottom:'7px', paddingLeft:'5px', paddingRight:'5px'}}>
        <div className='container-title clearfix' >
          <div id="info-contact" class="title-row" >
            
            <FontAwesomeIcon class="title-block" style={{ height: "10px" , width: "10px"}} icon={faPhone} />
            <div class="title-block" style={{ fontSize: "135%"}}>{number}</div>
            
            <FontAwesomeIcon class="title-block" style={{ height: "10px" , width: "10px"}} icon={faEnvelope} />
            <a class="title-block" href="`mailto:{email}`">
            <span class="title-block" id="et-info-email" style={{ fontSize: "130%"}}>{email}</span>
            </a>
          
          </div>
        </div>
      </div>
      </div>)}
      </div>
    );
  }

export default TitleHeader; 