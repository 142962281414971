import React from 'react';
import logo from '../images/logo3.jpeg';

function Header() {
  

    return (head());
}

export  function head() {
    const isDesktop = window.matchMedia("(max-width: 415px)").matches;
    return (
        <div style={{position: 'fixed'}}>
    {/* for mobile */}
                {!isDesktop && (<div  style = {{ backgroundColor: 'white', opacity: 0.7, paddingTop:'8px', paddingBottom:'0px', paddingLeft:'300px', paddingRight:'300px', width: '300%', marginTop:"30px"}}>
                  <div style={{display: 'inline-block'}}>
                    <h3 style={{display: 'inline-block', color: "rgb(6, 132, 152)",background: 'transparent', verticalAlign: 'baseline', fontSize:'300%', fontFamily: 'fantasy'}}>Wahab's</h3>
                    <h3 style={{display: 'inline-block', background: 'transparent', verticalAlign: 'baseline', fontSize:'300%', fontFamily: 'fantasy'}}> Holiday Cottage</h3>

                  </div>
                </div>)}
    {/* for desktop */}
                {isDesktop && (<div  style = {{ backgroundColor: 'white', opacity: 0.7, paddingTop:'8px', paddingBottom:'0px', paddingLeft:'30px', paddingRight:'300px', width: '300%', marginTop:"42px"}}>
                  <div style={{display: 'inline-block'}}>
                    <h3 style={{display: 'inline-block', color: "rgb(6, 132, 152)",background: 'transparent', verticalAlign: 'baseline', fontSize:'230%', fontFamily: 'fantasy'}}>Wahab's</h3>
                    <h3 style={{display: 'inline-block', background: 'transparent', verticalAlign: 'baseline', fontSize:'200%', fontFamily: 'fantasy'}}> Holiday Cottage</h3>

                  </div>
                </div>)}
      </div>
    );
  }

export default Header; 