import React from 'react';
import './index.css';
import scarf from './/images/scarf.jpg';
import poster from './/images/poster.jpg';
import poster2 from './/images/poster3.jpg';
import aari from './/images/catalog/aari.jpg'
import paper from './/images/catalog/paper.jpg'
import silk from './/images/catalog/Silkwool.jpg'
import pashmina from './/images/catalog/pashmina.jpg'
import wool from './/images/catalog/wool.jpg'
import "./style.css";
import hometext from './TextDetails.js';
import Header from './pages/Header.js';
import TitleHeader from './pages/TitleHeader.js';
import AccomodationImages from './pages/AccomodationImages.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faUsers} from '@fortawesome/free-solid-svg-icons';
import {faBed} from '@fortawesome/free-solid-svg-icons'
import {faHouseChimney} from '@fortawesome/free-solid-svg-icons'
import {faBath} from '@fortawesome/free-solid-svg-icons'
import {faDoorOpen} from '@fortawesome/free-solid-svg-icons'

const categories = [
  {
    to: "categories/aari",
    imgSrc:`${aari}`,
    alt: "img1",
    description: "Aari Work",
    className: "grid-two",
  },
  {
    to: "categories/pashmina",
    imgSrc:`${pashmina}`,
    alt: "img2",
    description: "Pashmina",
    className: "grid-one",
  },
  {
    to: "categories/silk-wool",
    imgSrc:`${silk}`,
    alt: "img3",
    description: "Silk Wool",
    className: "grid-four",
  },
  {
    to: "categories/papermachie",
    imgSrc:`${paper}`,
    alt: "img4",
    description: "Papermachie work",
    className: "grid-two-low",
  },
  {    to: "categories/cashmere",
    imgSrc:`${wool}`,
    alt: "img5",
    description: "Cashmere Wool",
    className: "grid-four-low",
  },
];
const isDesktop = window.matchMedia("(max-width: 415px)").matches;
const gotHomeText = hometext;
const amenitiesText = `Accepts Dogs, Broadband / Wifi, Coffee Machine, Cot available, Dishwasher, Drying Pod, Electrical Outside Socket, Gas Fire, Onsite Parking, Private Garden or Patio, Pub / Shop < 1 mile, Smart TV with Netflix, Smoke Free, Washing machine`;
const bedTyeText = `5 King Beds`;
function App() {
  
  var number = "+91-9622262227";
  var email = "info@wahabsinn.com";
  return (
    <div style={{backgroundColor:"#f2f2f2"}}>
    { !isDesktop && (<div><TitleHeader></TitleHeader>
      <div className='poster' style={{backgroundImage: `url(${poster2})`}}>
        <Header style={{width: '100%', marginTop:"10%"}} ></Header>
      </div>
    </div>
    )}
      { isDesktop && (<div><TitleHeader></TitleHeader>
      <div className='poster-mobile' style={{backgroundImage: `url(${poster2})`}}>
        <Header style={{width: '100%', marginTop:"10%"}} ></Header>
      </div>
    </div>
    )}

    {/* for mobile */}
              { isDesktop && (<div className='middle-tile' style={{font:"26px"}}>
                  <h3 style={{fontSize:"24px", fontStyle:"normal", fontFamily:"Georgia",textAlign:"center" , lineHeight:'1.5em', color: '#8e8e8e', fontWeight: '500'}}>{gotHomeText}</h3>
                  <div className='middle-tile-plus-mobile'>
                    <div className='bottom-container'>
                    <div className='component-a' id="photo-side">
                      <AccomodationImages/>
                    </div>                    
                    </div>
                    </div>
                  <div className='component-b' id="detail-side">
                    <div style={{padding:"1rem", background:"#f2f2f2", marginTop:"4%"} }> 
                      <h3 style={{fontSize:"24px", fontStyle:"normal", fontFamily:"Georgia",textAlign:"center" , lineHeight:'1.5em', color: '#8e8e8e', fontWeight: '500', float:"left"}}>Details</h3>
                      <div style={{ marginTop:"20%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faUsers} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Guests:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>10</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faHouseChimney} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Amenities:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>{amenitiesText}</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faBed} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Bed Type:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>{bedTyeText}</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faBath} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Bathrooms:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>5</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faDoorOpen} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Bedrooms:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>5</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                    </div>
                  </div>
                  
              </div>)}
    {/* for desktop */}
                { !isDesktop && (<div className='middle-tile' style={{font:"26px"}}>
                  <h3 style={{fontSize:"24px", fontStyle:"normal", fontFamily:"Georgia",textAlign:"center" , lineHeight:'1.5em', color: '#8e8e8e', fontWeight: '500'}}>{gotHomeText}</h3>
                  <div className='component-b' id="detail-side">
                    <div style={{padding:"1rem", position: "absolute", marginLeft:"50%", width:"40%", background:"#f2f2f2", marginTop:"4%"} }> 
                      <h3 style={{fontSize:"24px", fontStyle:"normal", fontFamily:"Georgia",textAlign:"center" , lineHeight:'1.5em', color: '#8e8e8e', fontWeight: '500', float:"left"}}>Details</h3>
                      <div style={{ marginTop:"20%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faUsers} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Guests:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>10</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faHouseChimney} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Amenities:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>{amenitiesText}</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faBed} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Bed Type:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>{bedTyeText}</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faBath} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Bathrooms:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>5</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                      <div style={{ marginTop:"5%"}}>
                      <FontAwesomeIcon  style={{  display:"inline", fontSize:"25px", fontFamily:"Font Awesome 5 Free", marginRight:"10px", fontWeight:"900", color:"#04adbf"}} icon={faDoorOpen} />
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"500", background:"transparent", color:"dimgray"}}>Bedrooms:</div>
                      <div  style={{  display:"inline",  display:"inline", fontSize:"200%", marginRight:"5px", fontWeight:"350", background:"transparent", color:"dimgray"}}>5</div>
                      <div style={{borderTop:"1px solid #8e8e8e", margin:"10px 16px"}}/>
                      </div>
                    </div>
                    </div>
                  <div className='middle-tile-plus'>
                    <div className='bottom-container'>
                    <div className='component-a' id="photo-side">
                      <AccomodationImages/>
                    </div>                    
                    </div>
                  </div>
              </div>)}
            
              
    </div>
  );

  
}

export  function grid() {

  return (
    <div className="home-container" style={{paddingTop:'5px', paddingBottom:'5px'}}>
      <div className="container">
        <div className="grid-container">
          {categories.map((category, index) => (
            <div key={index} className={`featured ${category.className}`}>
              <a Href={category.to}>
                <div id={`img${index + 1}`} className="lil-overlay"></div>
                <img src={category.imgSrc} alt={category.alt} />
                <p className="main-description">{category.description}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
