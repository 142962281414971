import { useState } from "react";
import {ChevronLeft, ChevronRight} from "react-feather";

export default function Carousel({
    children: slides
}) {
    const [curr, setCurr] = useState(0);
    const prev = () => setCurr((curr) => (curr == 0 ? slides.length -1 : curr -1));
    const next = () => setCurr((curr) => (curr == slides.length -1 ? 0 : curr + 1));

    return (
        <div>
        <div style={{position: "relative"}}>
            <div style={{display: "flex", transform:`translateX(-${curr * 100}%)`}}>{slides}</div>
            
        </div>
        <div style={{position: "absolute", inset:"0", display:"flex", alignItems: "center", padding: "1rem", justifyContent:"space-between"}}>
        <button onClick = {prev}  style={{padding: "1px", borderRadius: "9999px", backgroundColor:"white", boxShadow:"0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)", color: "#2d3748"}}>
            <ChevronLeft size={40}/>
        </button>
        <button onClick = {next} style={{padding: "1px", borderRadius: "9999px", backgroundColor:"white", boxShadow:"0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)", color: "#2d3748"}}>
            <ChevronRight size={40}/>
        </button>
        </div>
        </div>
    )
}