import Carousel from '../pages/Carousel.js';
import sunset from '../images/catalog/sunset.jpeg'
import realsunset from '../images/catalog/realsunset.jpg'
import yellow from '../images/catalog/yellow.jpg'


const slides = [sunset, realsunset, yellow];


export default function AccomodationImages(){
    return (
  
        <div className= "max-w-lg">
            <Carousel>
                {slides.map((s) => (
                    <img width="590" height="500" src={s}/>
                ))}
            </Carousel>
        </div> 
   
    );
}